<template>
  <section id="settings">
    <h1 id="page-title">{{ $t('settings') }}</h1>
    <b-row class="row-wide">
      <b-col md="auto">
        <div class="spacecard left-sideblock sm spacecard-menu mb-3 mb-md-3 mob-scroll" >
          <div v-if="![5,6].includes(roleId)">
            <router-link class="d-md-block d-inline-block"
                         v-for="(m,mind) in menu"
                         :to="m.to"
                         :key="'menu-p-'+mind">
              <component v-if="m.icon"
                         :is="m.icon"
                         class="menu-icon"/>
              {{ $t(m.title) }}
            </router-link>
          </div>
          <a class="d-block pointer"
             v-if="showTest"
             @click="$bvModal.show('testinternetmodal')">
            <QuestionSvg class="menu-icon"/>
            {{ $t('test_connection') }}
          </a>
        </div>
        <a class="spacecard sm left-sideblock d-md-block d-none text-center pointer text-danger" @click="logout">{{ $t('logout') }}</a>
      </b-col>
      <b-col md>
        <div class="spacecard" >
          <router-view/>
        </div>
        <a class="spacecard sm d-block d-md-none mt-3 text-center pointer text-danger" @click="logout">{{ $t('logout') }}</a>
      </b-col>
    </b-row>
    <InternetAndDevicesTestModal/>
  </section>
</template>

<script>
import AuthService from "@/services/auth.helper"
import InternetAndDevicesTestModal from "../../../parts/class/InternetAndDevicesTestModal"
import UserSvg from '@/assets/svg/user_profile.svg'
import DollarSvg from '@/assets/svg/dollar.svg'
import QuestionSvg from '@/assets/svg/question.svg'
import StarSvg from '@/assets/svg/star.svg'

export default {
  components: {
    InternetAndDevicesTestModal,
    QuestionSvg
  },
  data() {
    return {
      teacher_menu: [
        { to: "/instructor/settings/profile", title: "profile", icon: UserSvg },
        { to: "/instructor/settings/policies", title: "price_policies", icon: DollarSvg },
        // { to: "/instructor/settings/subscription", title: "subscription", icon: QuestionSvg }
      ],
      school_menu: [
        { to: "/school/settings/profile", title: "profile", icon: UserSvg },
        { to: "/school/settings/policies", title: "price_policies", icon: DollarSvg },
        // { to: "/school/settings/subscription", title: "subscription", icon: QuestionSvg },
        { to: "/school/settings/integrations", title: "integrations", icon: StarSvg },
      ],
      showTest: true
    }
  },
  mounted() {
  },
  methods: {
    logout() {
      this.$confirm(this.$t('are_you_sure_you_want_to_logout')).then(() => {
        AuthService.logout()
        this.$router.push('/login')
      })

    },
  },
  computed: {
    roleId() {
      return this.$store.state.user ? this.$store.state.user.primary_role_id : 2
    },
    menu() {
      if(!this.$store.state.user) {
        return this.teacher_menu
      }
      if([3,4].includes(this.roleId)) {
        return this.school_menu
      }
      return this.teacher_menu
    }
  },
  metaInfo() {
    return {
      title: this.$t('settings'),
    }
  },
}
</script>

<style scoped lang="scss">
  .menu-icon {
    fill: #424F58;
    margin-right: 10px;
  }
  .router-link-active {
    .menu-icon {
      fill: #000;
    }
  }
</style>


